import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Post from '../molecules/Post';

const webProjects = [
  {
    title: 'Onsite Planning',
    caption: 'Onsite is a web application that connects users with travel influencers to help plan their next trip.  Users can view profiles of planners, look at premade itineraries, and submit requests for custom trip planning.  The full stack application was built with React, Node, and AWS Backend Services',
    image: '/images/onsite-demo.png',
    tags: ['Full Stack', 'API Integrations', 'CI/CD', 'AWS'],
    url: 'https://onsite-demo.noboa.me',
    github_link: 'https://github.com/anoboa2/onsite-demo'
  },
  {
    title: 'Monsty',
    caption: 'Monsty is a [not-yet-existent] restaurant concept.  The webfront was made to showcase the brand concept and menu.  The webfront was made with React and Material UI.',
    image: '/images/monsty.png',
    tags: ['Front End', 'UI/UX', 'CI/CD', 'AWS'],
    url: 'https://monsty.noboa.me',
    github_link: 'https://github.com/anoboa2/monsty'
  },
  {
    title: 'Fantasy Baseball Projecting',
    caption: 'This was a personal project to help me prepare for a fantasy baseall draft.  The goal was to define several features that influence individual draft picks to help identify outlier picks for different rounds of the draft. The project was built with Python, scrapes a public ESPN API to pull player data, and applies stastical weights and biases to predict draft picks.',
    image: '/images/espn-fba.png',
    tags: ['Data Science', 'Python'],
    url: "https://espn-fba.noboa.me/",
    github_link: 'https://github.com/anoboa2/fantasy_baseball_analysis'
  },
  // {
  //   title: 'Taxi and Rideshare Analysis',
  //   caption: 'This project used publicly available NYC taxi and rideshare data to predict demand and pricing. The project was built with Python and uses a combination of linear regression and clustering to predict demand and pricing.',
  //   image: 'https://source.unsplash.com/random',
  //   tags: ['Data Science', 'Machine Learning', 'Python'],
  //   url: null,
  //   github_link: ''
  // },
  {
    title: 'Data Science and Machine Learning Coursework',
    caption: 'This is a large collection of coursework that I completed as part of a 12-month Data Science and Machine Learning course.  My certification of completion can be found here.',
    image: '/images/IBM-certificate.png',
    tags: ['Data Science', 'Machine Learning', 'Deep Learning', 'Python'],
    url: 'https://profile.edx.org/u/AlexanderNoboa',
    github_link: 'https://github.com/anoboa2/edxIBMcourses'
  },
  {
    title: 'GPT Chatbot',
    caption: 'This was a small project to briefly demo the capabilites of GPT-3.  The project was built with Python, Streamlit, and uses the OpenAI API to generate text based on user input. The project is hosted on AWS EC2.',
    image: '/images/chat-clone.png',
    tags: ['Generative AI/LLM\'s', 'Python'],
    url: 'https://chat.noboa.me:8501',
    github_link: 'https://github.com/anoboa2/chatgpt-clone'
  }
]

const blogPosts = [
  {
    title: 'IT vs Big Tech',
    image: 'https://source.unsplash.com/random',
    source: 'Medium',
  },
  {
    title: 'Engineering Management for Dummies',
    image: 'https://source.unsplash.com/random',
    source: 'Medium',
  },
  {
    title: 'Healthcare 3.0',
    image: 'https://source.unsplash.com/random',
    source: 'Twitter',
  },
  {
    title: 'Building a Data Science Portfolio',
    image: 'https://source.unsplash.com/random',
    source: 'Medium',
  }
]

const Projects = () => {
  return (
    <>
      <Container className="projects" sx={{ position: 'relative', display: 'block', width: '100%', maxWidth: '1440px', height: 'auto', pt: '5%', scrollSnapType: 'y mandatory', scrollSnapAlign: 'start' }}>
        <Typography variant="h3" component="h3" align="left" sx={{ mb: '5%' }}>
          Here's some of my work...
        </Typography>
        <Grid container spacing={6}>
          {webProjects.map((project, index) => (
            <Grid xs={12} md={6} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Post title={project.title} caption={project.caption} image={project.image} tags={project.tags} link={project.url} github={project.github_link}/>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* <Container className="blogs" sx={{ position: 'relative', width: '100%', maxWidth: '1440px', height: 'auto', pt: '5%', scrollSnapType: 'y mandatory', scrollSnapAlign: 'start' }}>
        <Typography variant="h3" component="h3" align="right" sx={{ mt: '2%', mb: '10%' }}>
          ...and some of my writing
        </Typography>
        <Grid container spacing={6}>
          {blogPosts.map((post, index) => (
            <Grid xs={12} md={6} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Post title={post.title} caption={post.caption} image={post.image} tags={post.tags}/>
            </Grid>
          ))}
        </Grid>
      </Container> */}
    </>
  )
}

export default Projects;